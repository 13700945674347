import React, { lazy, Suspense } from 'react';

import { Layout } from '@bilira-org/design';
import { DesignStablecoin } from '@bilira-org/design/root';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';

import { queryClient } from '@/libs/clientInstances/queryClient';
import RedirectSpinCard from '@Components/common/RedirectSpinCard';
import './app/i18Next';
import 'dayjs/locale/tr';
import './app/sentryConf';

const App = lazy(() => import('./app/App'));

const { Main, Container } = Layout;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <DesignStablecoin>
      <QueryClientProvider client={queryClient}>
        <Suspense
          fallback={
            <Layout color="neutral-200">
              <Container>
                <Main>
                  <RedirectSpinCard />
                </Main>
              </Container>
            </Layout>
          }
        >
          <App />
        </Suspense>
        <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      </QueryClientProvider>
    </DesignStablecoin>
  </React.StrictMode>,
);
