import { UserQueryKey, UserType } from '@bilira-org/react-utils';
import * as Sentry from '@sentry/react';
import { Query, QueryKey } from '@tanstack/react-query';

import { useGeneralStore } from '@Store/generalStore';

const handleQuerySuccess = (data: unknown, query: Query<unknown, unknown, unknown, QueryKey>) => {
  if (query.queryKey.includes(UserQueryKey.USERS) && data) {
    Sentry.setUser({ id: (data as UserType).id || 'Unknown' });
    useGeneralStore.getState().setUser(data as UserType);
  }
};

export default handleQuerySuccess;
