import { DirectQuoteType, UserType } from '@bilira-org/react-utils';
import { createWithEqualityFn } from 'zustand/traditional';

interface GeneralStoreType {
  languageModalActive: boolean;
  setLanguageModalActive: (value: boolean) => void;

  isLoggedIn: boolean;

  user: UserType | null;
  setUser: (value: UserType | null) => void;

  quote?: DirectQuoteType;
  setQuote: (value: DirectQuoteType) => void;

  legalDocuments: { activeConsentIndex: number; invalidConsentsLength: number; active: boolean };
  setLegalDocuments: (value: Partial<GeneralStoreType['legalDocuments']>) => void;

  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
}

export const useGeneralStore = createWithEqualityFn<GeneralStoreType>((set) => ({
  languageModalActive: false,
  setLanguageModalActive: (value: boolean) => set(() => ({ languageModalActive: value })),

  isLoggedIn: false,
  user: null,
  setUser: (value: UserType | null) => set(() => ({ user: value, isLoggedIn: !!value })),

  quote: undefined,
  setQuote: (value: DirectQuoteType) => set(() => ({ quote: value })),

  legalDocuments: { activeConsentIndex: 0, invalidConsentsLength: 0, active: false },
  setLegalDocuments: (value: Partial<GeneralStoreType['legalDocuments']>) => {
    set((state) => {
      const activeConsentIndex = value.activeConsentIndex || state.legalDocuments.activeConsentIndex;
      const invalidConsentsLength = value.invalidConsentsLength || state.legalDocuments.invalidConsentsLength;
      const active = activeConsentIndex < invalidConsentsLength;

      return { legalDocuments: { activeConsentIndex, invalidConsentsLength, active } };
    });
  },

  drawerOpen: false,
  setDrawerOpen: (value: boolean) => set(() => ({ drawerOpen: value })),
}));
