import { ERROR_PAGE_STATE, getBiLiraErrorCode, isAxiosError } from '@bilira-org/react-utils';
import { isHttpError } from '@bilira-org/react-utils/src/utils/httpError';
import * as Sentry from '@sentry/react';

import { useGeneralStore } from '@Store/generalStore';

const handleErrorCapture = (error: Error, type: string, hash?: string): void => {
  if (!isAxiosError(error)) {
    Sentry.captureException(error, {
      user: { id: useGeneralStore.getState().user?.id },
      tags: {
        errorType: type,
        biLiraErrorCode: ERROR_PAGE_STATE.UNHANDLED_ERROR,
      },
    });

    return;
  }

  const httpError = isHttpError(error);

  if (httpError.isCriticalError) {
    Sentry.captureException(error, {
      user: { id: useGeneralStore.getState().user?.id },
      tags: {
        errorType: type,
        biLiraErrorCode: getBiLiraErrorCode(error),
      },
    });
  }

  if (window.location.pathname === '/error') {
    return;
  }

  if (httpError.isLogout) {
    useGeneralStore.getState().setUser(null);
    return;
  }

  if (httpError.isServerUnavailable) {
    console.error(error);
    window.location.href = '/error';
  }
};

export default handleErrorCapture;
