import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en from '../assets/locales/en/default.json';
import tr from '../assets/locales/tr/default.json';

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    ns: ['default'],
    defaultNS: 'default',

    supportedLngs: ['en', 'tr'],
    resources: {
      en: { default: en },
      tr: { default: tr },
    },
    interpolation: {
      escapeValue: false, // react already safes from
    },
  })
  .then(() => console.debug('Locale initialized.'));
