import React from 'react';

import { Block, Card, Spinner, Text } from '@bilira-org/design';

type Props = {
  title?: string;
  description?: string;
};
const RedirectSpinCard = ({ title, description }: Props) => {
  return (
    <Block height="size-full" width="size-full" justify="center" items="center" p="lg">
      {/*<Card color="theme-wn" shadow style={{ maxWidth: '30rem' }} size="xl" mx="auto">*/}
      <Block justify="center" items="center" align="center" py="8xl">
        <Spinner size="xl" color="primary" />
        {title && (
          <Text heading size="xl" mt="xl">
            {title}
          </Text>
        )}
        {description && (
          <Text size="sm" mb="8xl" mt="md">
            {description}
          </Text>
        )}
      </Block>
      {/*</Card>*/}
    </Block>
  );
};

export default RedirectSpinCard;
